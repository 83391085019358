export default {
 
  async doParseServer(action, params, appId, appJSKey) {


    try {
      // console.log("this.appId: " + appId);

      if (action !== "dummyAction"){ // to try and stop parse loading
        const Parse = require('parse/node');
        Parse.serverURL = 'https://parseapi.back4app.com/';
        Parse.initialize(appId, appJSKey); 
        

        if (action === 'ggreview'){
          
          Parse.Cloud.run("logLinkFollow", params);
          
          const params2 = {
            toEmail: "appalert@thanku.app",
            subject: "We did logLinkFollow " + new Date().toISOString(),
            body: JSON.stringify(params, null, 2),
          };
          Parse.Cloud.run("sendEmail", params2);

        } else if (action == "validateUserLinkDetails"){

          // console.log("WE ARE FUCKNG VALIDATIN!");
          // const params2 = {
          //   toEmail: "appalert@thanku.app",
          //   subject: "We did validateUserLinkDetails " + new Date().toISOString(),
          //   body: "fook"
          // };

          // Parse.Cloud.run("sendEmail", params2);

          // console.log("validateUserLinkDetails params: " + JSON.stringify(params, null, 2));

          let selectUserData = await Parse.Cloud.run("validateUserLinkDetails", params);
          
          // console.log("doParseServer validateUserLinkDetails selectUserData: " + JSON.stringify(selectUserData, null,2));

          window.localStorage.setItem("tu", params.tu);
          window.localStorage.setItem("tuob", params.tuob); // now need to do this because as we go back to getURLDataIfAnyAndSetPart2 we have consumed the URL search paramaters which will be null and we need this value

          // console.log("tuob IS HERE " + window.localStorage.getItem("tuob"));

          return selectUserData;
        
        } else if (action === "fetchUserDetails"){

          // const params2 = {
          //   toEmail: "appalert@thanku.app",
          //   subject: "We did fetchUserDetails " + new Date().toISOString(),
          //   body: JSON.stringify(params, null, 2),
          // };

          // Parse.Cloud.run("sendEmail", params2);

          let userData = await Parse.Cloud.run("fetchUserDetails", params);

          // console.log("doParseServer fetchUserDetails userData: " + JSON.stringify(userData, null,2));

          return userData;

        } else if (action === "sendEmail"){

          console.log("about to sendEmail........");

          let action = params.body !== undefined ? (params.body.type !== undefined ? params.body.type : "none") : "params.body undefined";
        
          if (action !== "none"){
            console.log("about to do logRocket");
            let result = await this.doLoadLogRocket(action, params, appId, appJSKey);
            console.log("doLoadLogRocket result: " + result);
          }
          // console.log("about to send email: " + JSON.stringify(params, null, 2));
          Parse.Cloud.run("sendEmail", params);
        }

        return true;
      }
    } catch (e) {

      if (action === "validateUserLinkDetails") {
        console.log("doParseServer for validateUserLinkDetails ERROR: " + e.message);
        this.popUpMsgTitle = "Sorry";
        this.popUpMsgBody = "The link did not work correctly: " + e.message + ". Please copy this message and email it to tech@thanku.app. We apologise for the inconvenience.";
        this.showPopUpOk = true;
        const params = {
            toEmail: "appalert@thanku.app",
            subject: "ALERT! getURLDataIfAnyAndSet failed: " + e.message,
            body: "",
        };
        this.loadParse = false;
        this.loadParseToggle = !this.loadParseToggle;
        this.functionName = "sendEmail";
        this.paramsObject = params;
        this.loadParse = true;
      } else {
        console.log("doParseServer ERROR: " + e.message)
      }

      // sharedhome.doParseServer("sendEmail", params, this.appId, this.appJSKey);
      return true;
    }
  },

  async doLoadLogRocket(action, params) {
    // this must always be called by doParseServer, it was getting too confusing having multiple routes in
    try {

      if (action !== "dummyAction"){ // to try and stop parse loading
        
        // console.log("starting doLoadLogRocket");
        // const Parse = require('parse/node');
        // Parse.serverURL = 'https://parseapi.back4app.com/';
        // Parse.initialize(appId, appJSKey); 

        const LogRocket = require('logrocket');
        // import LogRocket from 'logrocket';

        let LRObjectId = undefined;

        if (window.localStorage.getItem("tuob") !== null) {
          LRObjectId = window.localStorage.getItem("tuob");
        } else if (window.localStorage.getItem("logRocketUserTimeStampId") !== null) {
          LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
        } else {
          window.localStorage.setItem("logRocketUserTimeStampId", new Date().getTime());
          LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
        }
  
        if (LRObjectId === "4g278vWUb1" || LRObjectId === "2fBkimFcIw" ){
          // return; // don't record myself
        }

        let LRDisplayname = undefined;

        if (window.localStorage.getItem("tudis") !== null) {
          LRDisplayname = window.localStorage.getItem("tudis");
        }

        if (action === "GoogleAds1stTime"){
          LRDisplayname = "LR doLoadLogRocket for Google Ads";
        } else if (action === "GoogleAdsRepeatTime"){
          LRDisplayname = "LR doLoadLogRocket for Google Ads REPEAT pageload/visit";
        } else if (action === "TappedOrderThankUBadges"){
          LRDisplayname = "LR doLoadLogRocket for Order Badges Email";
        } else if (action === "TappedDownloadThankUBrochure"){
          LRDisplayname = "LR doLoadLogRocket for Brochure Download";
        }


        LogRocket.init('thanku-alt-8/thanku-alt-8');//LogRocket.init("thanku-alt-7/thanku-alt-7"); //LogRocket.init('thanku-alt-6/thanku-alt-6'); //LogRocket.init('thanku-alt-5/thanku-alt-5'); //LogRocket.init('thanku-alt-4/thanku-alt-4'); //LogRocket.init('thanku-alt-3/thanku-alt-3');//LogRocket.init('thanku-alt-2/thanku-alt-2');//LogRocket.init('thanku-alt/tualt'); //LogRocket.init('fpi3vr/thanku');  //LogRocket.init('thanku-alt-10/thanku-alt-10'); //LogRocket.init('thanku-alt-9/thanku-alt-9'); //
        LogRocket.identify(LRObjectId, {
          name: LRDisplayname,
          // Add your own custom user variables here, ie:

        });

        // const params3 = {
        //   toEmail: "appalert@thanku.app",
        //   subject: "We did " + LRDisplayname + " " + new Date().toISOString(),
        //   body: params,
        // };

        // if we decide to step sending these emails can suppress that's fine, the log triggers are 
        // Parse.Cloud.run("sendEmail", params3);
        // DECIDED TO STOP - this is now only being access from doParseServer and we will know soon enough if the LogRocket side is working as there won't be any sessions - had we let this run beause of the body.type field it would have doubled up log records or require an extra field, bit of a faff

        return true;
      }


    } catch (e) {
      // console.log("error starting LogRocket for Google ads link follow " + e.message);
      const params = {
          toEmail: "appalert@thanku.app",
          subject: "ALERT! LogRocket initialisation ON SHAREDHOME for Google ads link follow, error: " + e.message,
          body: "",
      };
      this.loadParse = false;
      this.loadParseToggle = !this.loadParseToggle;
      this.functionName = "sendEmail";
      this.paramsObject = params;
      this.loadParse = true;

      return false;
    }
         
       

  }
}